<template>

  <v-footer
    dark
    padless
    v-if="!token"
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-title class="teal">
        <strong class="subheading">Get connected with us on social networks!</strong>

        <v-spacer></v-spacer>

        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          dark
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-2 white--text text-center">
        {{ new Date() }} — <strong>Centeral</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
// eslint-disable-next-line no-unused-vars

import {mapGetters} from 'vuex';

  export default {
    name:'Footer',
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
    watch: {
    // whenever question changes, this function will run
    token: function (newVal, oldVal) {
        if(newVal){
          console.log('hide footer')
        }
        else{
          console.log('show footer')
        }
      }
    },
    computed:{
      ...mapGetters(['token']),
    } 
  }
</script>