<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn fab light small color="yellow" v-on="on">
          <v-icon dark>mdi-account-edit</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            :color="alert_color"
            :value="message"
            v-model="alert"
            dismissible
          >
            Result : {{ message }}
          </v-alert>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="user.username"
                    :rules="nameRules"
                    :counter="10"
                    label="Username*"
                    value="test"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model.trim="user.email"
                    :rules="emailRules"
                    label="E-mail*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    :items="roles"
                    label="Role*"
                    v-model="user.role"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    tile
                    color="dark"
                    @click="edit_password = !edit_password"
                  >
                    <v-icon left> mdi-pencil </v-icon>
                    Change password
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-if="edit_password"
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[passwordRules.min, passwordRules.required]"
                    :type="show ? 'text' : 'password'"
                    name="input-10-2"
                    label="Password*"
                    hint="At least 6 characters"
                    class="input-group--focused"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-spacer></v-spacer>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = !dialog">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="editUser"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";

export default {
  name: "UserEdit",
  props: ["user"],
  data: () => ({
    edit_password: false,
    dialog: false,
    show: false,
    old_email: "",
    roles: ["Admin", "Normal"],
    alert: false,
    alert_color: "red",
    message: "",
    valid: false,
    password: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 6 || "Min 6 characters",
    },
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    editUser() {
      this.validate();
      const user = {
        username: this.user.username,
        email: this.user.email,
        old_email: this.old_email,
        password: this.password,
        role: this.user.role,
      };
      axios
        .post("edit/", user)
        .then((response) => {
          this.alert = true;
          this.alert_color = "success";
          this.message = response.data.success;
          this.old_email = this.user.email;
          this.$emit("editFinished");
        })
        .catch((error) => {
          this.alert = true;
          this.alert_color = "error";
          this.message = error.response.data.error;
        });
    },
  },
  mounted() {
    this.old_email = this.user.email;
  },
};
</script>