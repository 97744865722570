<template>
  <div class="text-center">
    <v-snackbar timeout="-1" v-model="enable" absolute top :color="color" elevation="24">
      <v-icon dark right> mdi-checkbox-marked-circle </v-icon>

      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="close"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "Notification",
  props: {
    enable: {},
    notification: {},
    color: {
      default: "green",
    },
  },
  methods: {
    close() {
      this.$emit("closeNotification", false);
    },
  },

};
</script>
