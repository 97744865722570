import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import Profile from "../views/Profile.vue";
import Users from "../components/profile-components/Users.vue";
import Orders from "../components/profile-components/Orders.vue";
import Settings from "../components/profile-components/Settings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    path: "/users",
    component: Users,
  },
  {
    path: "/orders",
    component: Orders,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/profile",
    component: Profile,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/signup", "/about", "/"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  if (!authRequired && loggedIn) {
    console.log("You are logged in you can't access the public pages");
    return next("/profile");
  }
  if (authRequired && !loggedIn) {
    console.log("You are not logged in you can't access the private pages");
    return next("/login");
  }
  if (role != "Admin" && to.path.includes("users")) {
    console.log("You can't access this route you are not an admin");
    return next("/profile");
  }
  next();
});
export default router;
