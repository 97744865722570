/*In this file you can add the path to any component and it will be accesible by every vue page
 without the need to import and define it as component in that vue page */
// Core Components
import Widget from "../components/Widget.vue";
import RespondOrder from "@/components/profile-components/RespondOrder";
import MessageDialog from "@/components/profile-components/MessageDialog";
import ExtractFeatures from "@/components/profile-components/ExtractFeatures";
import Loading from "@/components/Loading";
import UserForm from "@/components/profile-components/UserForm";
import UserEdit from "@/components/profile-components/UserEdit";
import Notification from "@/components/Notification";
import Terms from "@/components/TermsOfService";
import Footer from "@/components/Footer";
import Sidebar from "@/components/profile-components/SideBar";

function setupComponents(Vue) {
  Vue.component("widget", Widget);
  Vue.component("RespondOrder", RespondOrder);
  Vue.component("MessageDialog", MessageDialog);
  Vue.component("ExtractFeatures", ExtractFeatures);
  Vue.component("Loading", Loading);
  Vue.component("UserForm", UserForm);
  Vue.component("UserEdit", UserEdit);
  Vue.component("Notification", Notification);
  Vue.component("Terms", Terms);
  Vue.component("Footer", Footer);
  Vue.component("Sidebar", Sidebar);
}

export { setupComponents };
