<template>
  <v-container fluid>
    <v-data-table
      :loading="this.loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="users"
      :items-per-page="5"
      class="elevation-1 ma-6"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item._id }}</td>
          <td>{{ row.item.username }}</td>
          <td>{{ row.item.email }}</td>
          <td>{{ row.item.role }}</td>
          <td>
            <v-btn light color="red" small @click="removeUser(row.item)">
              <v-icon dark>mdi-account-remove</v-icon>
            </v-btn>
          </td>
          <td>
            <UserEdit v-bind:user="row.item" v-on:editFinished="fetchUsers()">
            </UserEdit>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-card elevation="11" outlined class="ma-6 text-center">
      <h2 class="ma-6">Add user accounts</h2>
      <UserForm v-on:editFinished="fetchUsers()"></UserForm>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import { bus } from "@/main";

export default {
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "_id",
        },
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Remove", sortable: false },
      ],
      users: [],
    };
  },
  methods: {
    fetchUsers() {
      axios
        .get("users/")
        .then((response) => {
          //console.log(response);
          this.users = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    removeUser(user) {
      axios
        .post("remove/", user)
        .then((response) => {
          this.fetchUsers();
          bus.$emit("notification", {
            enable_notification: true,
            message: response.data.success,
            type: "success",
          });
        })
        .catch((error) => {
          var message = "";
          if (error.response) {
            message = error.response.data.error;
          } else {
            message = "Something went wrong";
          }
          bus.$emit("notification", 
          { 
            enable_notification : true ,
            message : message,
            type: "error"
           });
        });
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>