<template>
  <v-container fluid>
    <v-card elevation="11" outlined tile>
      <h2 class="ma-6">Configure API endpoints</h2>
      <v-divider></v-divider>
      <v-card elevation="11" outlined tile class="ma-6">
        <h2 class="ma-6" color="info">
          <v-icon color="info">mdi-facebook</v-icon> Facebook API endpoint
        </h2>
        <v-divider></v-divider>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                label="Verify token used for the webhook (for every company)"
                disabled
                value="labmember"
                class="ma-6"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                label="Webhook endpoint to copy"
                readonly
                v-model="webhook_url"
                class="mt-6"
                ref="textToCopy"
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-12"
                    small
                    @click="copyText"
                    v-bind="attrs"
                    v-on="on"
                    >copy</v-btn
                  >
                </template>
                <span
                  >Copy this url into the messaging webhook url in the facebook
                  developer app configuration</span
                >
              </v-tooltip>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="facebook_access_key"
                :rules="rules"
                label="Page access key"
                class="ma-6"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                class="ma-6 text-center"
                color="success"
                :disabled="!valid"
                @click="saveSettings"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
import { bus } from "@/main";
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      valid: false,
      webhook_url: axios.defaults.baseURL + "webhook/",
      facebook_access_key: "",
      loading: false,
      rules: [
            v => !!v || 'This field is required',
        ],
    };
  },
  methods: {
    emitLoadingEvent(enabled) {
      bus.$emit("loading", enabled);
    },
    getUserId() {
      this.loading = true;
      axios
        .get("user/")
        .then((response) => {
          this.loading = false;
          this.webhook_url += response.data.user._id;
          if("facebook_access_key" in response.data.user){
            this.facebook_access_key = response.data.user.facebook_access_key
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    saveSettings() {
      this.loading = true;
      const data = {
        facebook_access_key: this.facebook_access_key,
      };
      axios
        .post("settings/", data)
        .then((response) => {
          this.loading = false;
          bus.$emit("notification", 
          { 
            enable_notification : true ,
            message : response.data.success,
            type: "success"
           });
        })
        .catch((error) => {
          this.loading = false;
          bus.$emit("notification", 
          { 
            enable_notification : true ,
            message : error.response.data.error,
            type: "error"
           });
        });
    },
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    },
  },

  mounted() {
    this.getUserId();
  },
  watch: {
    loading: function (newVal, oldVal) {
      this.emitLoadingEvent(newVal);
    },
  },
};
</script>
