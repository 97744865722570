<template>
  <nav>
    <v-toolbar dark flat v-if="!token">
      <v-toolbar-title>Centered</v-toolbar-title>

      <v-spacer></v-spacer>
      <div v-for="link in publicLinks" :key="link.text">
        <v-btn router :to="link.route">
          {{ link.text }}
        </v-btn>
      </div>
    </v-toolbar>
    <v-app-bar dark elevate-on-scroll v-else>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Centered</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout"> Logout </v-btn>
    </v-app-bar>

    <Notification
      v-bind:enable="enable_notification"
      :notification="notification"
      :color="color"
      v-on:closeNotification="updateNotification($event)"
    >
    </Notification>

    <v-navigation-drawer app v-model="drawer" class="dark" v-if="token">
      <v-layout column align-center>
        <v-flex class="mt-5">
          <p class="white--text subheading mt-1">Menu</p>
        </v-flex>
        <v-flex class="mt-4 mb-3"> </v-flex>
      </v-layout>
      <v-list rounded>
        <v-subheader>ROUTES</v-subheader>
        <v-list-item-group v-model="selectedItem" color="dark">
          <v-list-item
            v-for="link in privateLinks"
            :key="link.text"
            router
            :to="link.route"
          >
            <v-list-item-icon>
              <v-icon v-text="link.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="link.text"> </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <Loading v-bind:overlay="overlay"> </Loading>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { bus } from "@/main";

export default {
  name: "Sidebar",
  created() {
    this.listenLoadingEvent();
    this.listenNotificationEvent();
  },
  data() {
    return {
      overlay: false,
      enable_notification: false,
      notification: "Somthing went wrong",
      color: "success",
      drawer: true,
      selectedItem: null,
      privateLinks: [
        { icon: "dashboard", text: "Profile", route: "/profile" },
        { icon: "mdi-account-plus", text: "Users", route: "/users" },
        { icon: "mdi-wechat", text: "Orders", route: "/orders" },
        { icon: "mdi-cog", text: "Settings", route: "/settings" },
      ],
      publicLinks: [
        { icon: "", text: "Home", route: "/" },
        { icon: "", text: "About", route: "/about" },
        { icon: "", text: "Login", route: "/login" },
        { icon: "", text: "Sign Up", route: "/signup" },
      ],
    };
  },
  methods: {
    updateNotification(val) {
      this.enable_notification = val;
    },
    logout() {
      localStorage.removeItem("token");
      this.$store.dispatch("setToken", null);
      this.$router.push("/");
    },
    listenLoadingEvent() {
      bus.$on("loading", (data) => {
        this.overlay = data;
      });
    },
    listenNotificationEvent() {
      bus.$on("notification", (data) => {
        this.updateNotification(data.enable_notification);
        this.notification = data.message;
        this.color = data.type;
      });
    },
  },
  computed: {
    ...mapGetters(["token"]),
  },

  mounted() {
    if (localStorage.getItem("token")) {
      this.overlay = true;
      axios
        .get("user/")
        .then((response) => {
          //console.log(response);
          if (response.data.user) {
            this.$store.dispatch("setRole", response.data.user);
            localStorage.setItem("role", response.data.user.role);
          }
          this.overlay = false;
        })
        .catch((error) => {
          this.color = "red";
          this.enable_notification = true;
          this.logout();
          setTimeout(() => {
            this.enable_notification = false;
          }, 5000);
          if (error.response) {
            console.log(error.response.data.description);
            this.notification = error.response.data.description;
          }
          this.overlay = false;
        });
    }
  },
  watch: {
    enable_notification: function(newVal, oldVal) {
      if (newVal == true) {
        setTimeout(() => {
          this.updateNotification(false);
        }, 5000);
      }
    },
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
          this.error = true;
        }, 15000);
    },
  },
};
</script>
