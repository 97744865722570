<template>
  <v-card class="overflow-hidden pa-6" color="light lighten-1" dark>
    <v-toolbar flat color="light">
      <v-icon>mdi-account</v-icon>
      <v-toolbar-title class="font-weight-light">
        User Profile
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <v-alert :color="alert_color" :value="message" v-model="alert" dismissible>
      Result : {{ message }}
    </v-alert>
    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model.trim="name"
              :rules="nameRules"
              :counter="10"
              label="Username"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model.trim="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[passwordRules.required, passwordRules.min]"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              hint="At least 6 characters"
              value="password"
              class="input-group--focused"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              :items="roles"
              label="Role"
              v-model="role"
              value="Admin"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-btn color="success" @click="createUser" :disabled="!valid">
        Add
        <v-icon dark>mdi-account-plus</v-icon>
      </v-btn>
    </v-form>
  </v-card>
</template>
<script>
import axios from "axios";
export default {
  name: "UserForm",
  data() {
    return {
      show: false,
      roles: ["Admin", "Normal"],
      role: "Normal",
      alert: false,
      alert_color: "red",
      message: "",
      valid: false,
      name: "",
      password: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 6 || "Min 6 characters",
      },
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    createUser() {
      this.validate();
      const user = {
        username: this.name,
        email: this.email,
        password: this.password,
        role: this.role,
      };
      axios
        .post("add/", user)
        .then((response) => {
          console.log(response);
          this.alert = true;
          this.alert_color = "success";
          this.message = response.data.success;
          this.$emit("editFinished");
        })
        .catch((error) => {
          console.log(error.response.data.error);
          this.alert = true;
          this.alert_color = "error";
          this.message = error.response.data.error;
        });
    },
  },
};
</script>