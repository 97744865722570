<template>
 <v-container fluid>
  <div class="about">
    <h1>This is a HOME page</h1>
  </div>
 </v-container> 
</template>

<script>

  export default {
    name: 'Home',
    components: {
    },
    computed:{
    }
  }
</script>
