<template>
  <v-container fluid>
    <v-card
      elevation="2"
      class="mx-auto"
      color="secondary"
      max-width="600"
      outlined
    >
      <v-alert color="error" :value="error">
        Something went wrong {{ error_msg }}
      </v-alert>
      <v-form ref="form" v-model="valid">
        <v-text-field
          class="pa-md-4 mx-lg-auto ma-6"
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          label="Password"
          :rules="passwordRules"
          required
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          class="input-group--focused pa-md-4 mx-lg-auto ma-6"
          @click:append="show = !show"
        ></v-text-field>

        <v-btn class="ma-6" :disabled="!valid" color="success" @click="login">
          Login
        </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { bus } from "@/main";
import axios from "axios";

export default {
  data: () => ({
    overlay: false,
    email: "",
    password: "",
    checkbox: false,
    show: false,
    valid: false,
    error: false,
    error_msg: "",
    emailRules: [(v) => !!v || "Email is required"],
    passwordRules: [(v) => !!v || "Password is required"],
  }),
  components: {},
  methods: {
    login() {
      this.overlay = true;
      const data = {
        email: this.email,
        password: this.password,
      };
      axios
        .post("login/", data)
        .then((response) => {
          console.log(response.data.access_token);
          this.overlay = false;
          localStorage.setItem("token", response.data.access_token);
          this.$store.dispatch("setToken", response.data.access_token);
          this.$router.go(); //refresh the page to update the header authorization in axios
          this.$router.push("/profile");
        })
        .catch((error) => {
          this.overlay = false;
          this.error = true;
          if (error.response) {
            this.error_msg = error.response.data.error;
          } else {
            this.error_msg = "Connexion problem";
          }
        });
    },
  },
  mounted() {
    bus.$emit("loading", false);
  },
  watch: {
    // whenever overlay changes, this function will run
    overlay: function (newVal, oldVal) {
      bus.$emit("loading", newVal);
    },
  },
};
</script>
