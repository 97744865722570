<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn fab light color="info" small rounded v-on="on">
          <v-icon dark>mdi-send</v-icon>
        </v-btn>
      </template>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          color="purple"
          size="50"
        ></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-title>
          <span class="text-h5">Send message</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            :color="alert_color"
            :value="response_message"
            v-model="alert"
            dismissible
          >
            Result : {{ response_message }}
          </v-alert>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="this.recepient_id"
                    label="User ID"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model.trim="message"
                    clearable
                    :rules="messageRules"
                    clear-icon="mdi-close-circle"
                    label="Message*"
                    value="This is clearable text."
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-spacer></v-spacer>
          <small>* indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = !dialog">
            Close
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="sendMessage"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  name: "RespondOrder",
  props: ["recepient_id"],
  data: () => ({
    overlay: false,
    edit_password: false,
    dialog: false,
    alert: false,
    alert_color: "red",
    message: "",
    response_message: "",
    valid: false,
    messageRules: [(v) => !!v || "Message can't be empty"],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    sendMessage() {
      this.validate();
      const data = {
        id: this.recepient_id,
        message: {
          text: this.message,
        },
      };
      this.overlay = true;
      axios
        .post("send/", data)
        .then((response) => {
          //console.log(response);
          this.alert = true;
          this.alert_color = "success";
          this.response_message = response.data.success;
          this.overlay = false;
        })
        .catch((error) => {
          try {
            const obj = JSON.parse(error.response.data.error);
            if (obj) {
              //console.log(obj.error.message);
              this.response_message = obj.error.message;
            } else {
              this.response_message = "Somthing went wrong";
            }
            this.alert = true;
            this.alert_color = "error";
          } catch (error) {
            console.log(error);
          }
          this.overlay = false;
        });
    },
  },
};
</script>