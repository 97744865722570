<template>
  <v-row justify="center">
  
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          fab
          rounded
          small
          v-bind="attrs"
          v-on="on"
        >
        <v-icon dark>mdi-eye</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Message</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;" class="mt-6">
        {{ text }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions            
        class="d-flex justify-center"
        >
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    name: 'MessageDialog',
    props:['text'],
    data () {
      return {
        dialog: false,
      }
    },
  }
</script>