<template>
  <v-app>
    <v-main>
      <Sidebar />
      <router-view/>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  
  data: () => ({
  })

};
</script>
