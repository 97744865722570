<template>
  <v-row justify="center">
  
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          fab
          rounded
          small
          v-bind="attrs"
          v-on="on"
        >
        <v-icon dark>mdi-text</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>NLP feature extraction result</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;" class="mt-6">
        {{ result }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions            
        class="d-flex justify-center"
        >
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from 'axios'
  export default {
    name: 'ExtractFeatures',
    props:['text'],
    data () {
      return {
        dialog: false,
        result:"Waiting for response from the server ..."
      }
    },
     watch: {
    dialog: function(newVal, oldVal) {
      if (newVal == true) {
        axios
        .get("extract_features/"+this.text+"/", {baseURL: 'https://extraction-service-nlp.herokuapp.com/'})
        .then((response) => {
          if(response.data.result.length !== 0){
            this.result = response.data.result
          }
          else{
              this.result = "No fetures found in this text !"
          }
        })
        .catch((error) => {
         console.log(error);
        });
      }
    },
    }
  }
</script>