<template>
<v-container fluid >
<div class="text-right ma-6">
    <v-btn
      color="blue darken-1"
      text
      @click="fetchOrders"
    >
    <v-icon dark>mdi-refresh</v-icon>
      Refresh
    </v-btn>
    
  </div>
    <v-data-table 
    :loading="this.loading"
    loading-text="Loading... Please wait"
    :headers="headers"
    :items="orders"
    :items-per-page="20"
    class="elevation-1 ma-6">
      <template v-slot:item="row">
          <tr>
            <td>{{row.item.sender_id}}</td>
            <td>{{row.item.api_provider}}</td>
            <td class="truncate" @click="show">{{row.item.text}} </td>
            <td><extract-features v-bind:text="row.item.text"/> </td>
            <td><MessageDialog v-bind:text="row.item.text"/> </td>
            <td>
            <RespondOrder 
               v-bind:recepient_id="row.item.sender_id"
            > 
            </RespondOrder>
            </td>
          </tr>
      </template>
    </v-data-table>
</v-container>
</template>
<script>
import axios from 'axios'

  export default {
    data () {
      return {
        loading: true,
        autoFetch: false,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'sender_id',
          },          
          { text: 'Provider', value: 'api_provider'},
          { text: 'Message', value: 'text'},

        ],
        orders: [],
      }
    },
    methods:{
       show() {
         console.log("showing");
       },
       fetchOrders(){
        this.loading=true;
        axios.get('orders/')
        .then(
          response => {
            this.orders = response.data;
            this.loading=false;
          }
        )
        .catch(
          error => {
            this.loading=false;
          }
        );

      },
    },
    mounted(){
      this.fetchOrders();
    }
  }
</script>
<style>
    .truncate {
      max-width: 500px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  </style>