<template>
  <v-container fluid>
    <v-card elevation="2" class="mx-auto ma-6" color="secondary" max-width="600" outlined>
      <v-alert color="error" :value="error" dismissible>
        Something went wrong {{ error_msg }}
      </v-alert>
      <v-form ref="form" v-model="valid">
        <v-text-field
          class="pa-md-4 mx-lg-auto ma-6"
          v-model="name"
          :counter="10"
          :rules="nameRules"
          label="Username"
          required
        ></v-text-field>

        <v-text-field
          class="pa-md-4 mx-lg-auto ma-6"
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          label="Password"
          required
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          hint="At least 6 characters"
          value="password"
          class="input-group--focused pa-md-4 mx-lg-auto ma-6"
          @click:append="show = !show"
        ></v-text-field>

        <v-text-field
          class="pa-md-4 mx-lg-auto ma-6"
          v-model="company_name"
          :rules="companyRules"
          label="Company Name"
          required
        ></v-text-field>

        <v-checkbox
          class="pa-md-4 mx-lg-auto ma-6"
          v-model="checkbox"
          :rules="[(v) => !!v || 'You must agree to continue!']"
          label="Yes I agree on your terms of service."
          required
        >
        </v-checkbox>
        <Terms class="pa-md-4 mx-lg-auto"></Terms>
        <v-btn :disabled="!valid" color="success" class="ma-6" @click="register">
          Create Account
        </v-btn>

        <v-btn color="error" class="ma-6" @click="reset"> Reset Form </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { bus } from "@/main";

export default {
  data: () => ({
    valid: false,
    overlay: false,
    name: "",
    created: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be more than 6 characters",
    ],
    company_name: "",
    companyRules: [(v) => !!v || "Company Name is required"],
    show: false,
    checkbox: false,
    error: false,
    error_msg: "",
    user: {
      username: "",
      email: "",
      password: "",
      company_name: "",
    },
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    register() {
      this.validate();
      this.error = false;
      this.overlay = true;
      const data = {
        username: this.name,
        email: this.email,
        password: this.password,
        company_name: this.company_name,
        role: "Admin",
      };
      axios
        .post("signup/", data)
        .then((response) => {
          this.overlay = false;
          this.created = true;
          this.$router.push("/login");
          bus.$emit("notification", 
          { 
            enable_notification : true ,
            message : response.data.success,
            type: "success"
           });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.error);
            this.error_msg = error.response.data.error;
          } else {
            this.error_msg = "Connexion problem";
          }
          this.overlay = false;
          this.error = true;
        });
    },
  },
  mounted(){
     bus.$emit("loading", false);
  },
  watch: {
    // whenever overlay changes, this function will run
    overlay: function (newVal, oldVal) {
      bus.$emit("loading", newVal);
    },
  },
};
</script>
