import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

//import User from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    role: 'Admin',
    token: localStorage.getItem('token'),
    enable:false,//notification
  },
  getters:{
    role: (state) =>{
      return state.role;
    },
    token: (state) =>{
      return state.token;
    },
    enable: (state) =>{
      return state.enable;
    },
  },
  mutations: {
    role(state,role){
      state.role = role;
    },
    token(state,token){
      state.token = token;
    },
    enable(state,enable){
      state.enable = enable;
    },
  },
  actions: {
    setRole(context,role){
      context.commit('role',role);
    },
    setToken(context,newToken){
      context.commit('token',newToken);
    },
    pushNotification(context,enable){
       context.commit('enable',enable);
    }
  },
  modules: {
    //user: User
  }
})
